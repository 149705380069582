import React from "react";
import { StaticImage } from 'gatsby-plugin-image';
import Navbar from "../components/Navbar/Navbar";
import Container from "../components/Container/Container";
import Footer from "../components/Footer/Footer";

import classes from "./styles/AboutUs.module.css";

const AboutUs = () => {
	return (
		<div className={classes.AboutUs}>
			<div className="navbar">
				<Navbar />
			</div>

			<div className={classes.content}>
				<Container>
					<div className={classes.people}>
						<h2>Meet the Team</h2>
						<div className={classes.person}>
							<h3>Sowmya Balla</h3>
							<p>Sowmya graduated from Auckland University of Technology with a Bachelor of Health Sciences in Podiatry.  Sowmya has a keen interest in Diabetes High Risk Foot and general care. She mixes her clinical time between the Auckland Diabetes Centre, private practice and Retirement Villages.  Sowmya is also multilingual as she speaks English, Telugu and Hindi fluently.</p>
							<p>Sowmya is passionate about her profession and specializes in providing high quality of care in general podiatry services such as minor surgery, Diabetic foot and wound care.</p>
						</div>
					</div>

				</Container>
			</div>

			<div className="footer">
				<Footer />
			</div>
		</div>
	)
}

export default AboutUs;
